:root {
  --primary-color: #df1818;
  --secondary-color: #f2f2f2;
  --background-dark-color: #05070b;
  --accent-color: #121824;
  --text-color: #e4e4e4;

  --scrollbar-color: #121824;
  --scrollbar-hover-color: #151b28;
}

h1, h2, h3, h4, h5, h6, p, label {
  color: var(--text-color);
}

input {
  background-color: var(--accent-color);
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  height: 50px;
  width: calc(100% - 15px);
  border-radius: 30px;
  padding-left: 15px;
  margin-bottom: 20px;
}

input[type="submit"] {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  height: 50px;
  width: 100%;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 40px;
}

.App {
  background-color: var(--background-dark-color);
  color: var(--font-light-color);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  overflow-x: hidden;
}

.home-page, .profile-page, .video-page, .upload-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow-x: hidden;
}

/*Header*/
.header {
  width: calc(100% - 40px);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.header .logo {
  color: var(--text-color);
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
}
.header .logo span {
  color: var(--primary-color);
}
.header .profile {
  margin-top: 10px;
}
.header .profile .pfp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  overflow: hidden;
  cursor: pointer;
}
.header .profile ion-icon {
  color: var(--text-color);
  font-size: 2.5rem;
}

/*Login Page*/
.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.login-page:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background-image: url("./content/theGang.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.login-page .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  position: relative;
}
.login-page .login-form .noAccount {
  color: var(--text-color);
  margin-top: 0;
  font-size: 0.9rem;
  margin-bottom: 0;
}
.login-hr {
  width: 150%;
  height: 1px;
  margin: 50px 0;
  border: none;
  background-color: var(--text-color);
}
.hc-auth-button {
  width: 60%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 30px;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  background-color: white;
  cursor: pointer;
  border: 3px solid #fa19d1;
}
.hc-auth-button:hover {
  background-color: #fa19d1;
  color: white;
}

/*Register Page*/
.register-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.register-page:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background-image: url("./content/theGang.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.register-page .register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  position: relative;
}
.register-page .register-form .haveAccount {
  color: var(--text-color);
  margin-top: 0;
  font-size: 0.9rem;
  margin-bottom: 0;
}

/*Home Page*/
.home-page .home-content .big-title {
  width: 100%;
  height: fit-content;
  overflow-y: hidden;
}
.home-page .home-content .big-title .big-image {
  width: 100%;
  aspect-ratio: 16/4;
}
.home-page .home-content .video-container {
  width: calc(100vw - 40px);
  height: fit-content;
  overflow-y: hidden;
  padding: 0 20px;
}
.home-page .home-content .video-container .recomended {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(6, 16.6%);
}
.home-page .home-content .video-container .video {
  display: block;
  height: fit-content;
  margin: 0 10px;
  text-decoration: none;
}
.home-page .home-content .video-container .video img {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 5px;
}

/*Profile Page*/
.profile-page .profile-content {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow-x: hidden;
}
.profile-page .profile-content .profile-container {
  width: calc(100vw - 80px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  padding: 0 40px;
}
.profile-page .profile-content .profile-container div {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.profile-page .profile-content .profile-container hr {
  width: 80%;
  height: 1px;
  margin: 50px 0;
  border: none;
  background-color: var(--text-color);
}
.profile-page .profile-content .profile-container span {
  color: var(--primary-color);
}
.profile-page .profile-content .profile-container .btn {
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 30px;
  color: var(--text-color);
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  background-color: var(--primary-color);
  cursor: pointer;
}
.profile-page .profile-content .profile-container .btn2 {
  width: 20%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 30px;
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}
.profile-page .profile-content .profile-container .videos {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-column-gap: 2%;
}
.profile-page .profile-content .profile-container .videos .video {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  cursor: pointer;
}
.profile-page .profile-content .profile-container .videos .video img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}

/*Video Page*/
.video-page .video-content {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.video-page .video-content .video-container {
  width: calc(100vw - 80px);
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
}
.video-page .video-content .video-container .video {
  width: 50%;
  height: fit-content;
  margin-right: 30px;
  aspect-ratio: 16/9;
}
.video-page .video-content .video-container .video video {
  width: fit-content;
  height: 100%;
  margin-bottom: 20px;
}
.video-page .video-content .video-container .video-info {
  width: calc(50% - 30px);
}
.video-page .video-content .video-container .video-info a {
  display: inline;
}